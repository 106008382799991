var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-graphic-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('dashboard')))])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/users/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-users-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('user')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/users/1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('organizer')))])])])]}}])}),_c('router-link',{attrs:{"to":"/users/0"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('single_user')))])])])]}}])}),_c('router-link',{attrs:{"to":"/users/2"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('guest')))])])])]}}])}),_c('router-link',{attrs:{"to":"/users/-1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('unapproved_organizer')))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/event/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-event-calendar-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('event')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/event/events"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('event')))])])])]}}])}),_c('router-link',{attrs:{"to":"/event/posts"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('post')))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/transaction/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-euro-sign"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('transaction')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/transaction/transactions"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('transaction')))])])])]}}])}),_c('router-link',{attrs:{"to":"/transaction/refund"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('refund')))])])])]}}])}),_c('router-link',{attrs:{"to":"/transaction/withdraws"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('withdraw')))])])])]}}])}),_c('router-link',{attrs:{"to":"/transaction/report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('report')))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/drink/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-cocktail"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('drink')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/drink/drinks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('drink')))])])])]}}])}),_c('router-link',{attrs:{"to":"/drink/orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('orders')))])])])]}}])}),_c('router-link',{attrs:{"to":"/drink/payout"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('withdraw')))])])])]}}])}),_c('router-link',{attrs:{"to":"/drink/report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('report')))])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":"/album"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-camera"}),_c('span',{staticClass:"menu-text"},[_vm._v("Album")])])])]}}])}),_c('router-link',{attrs:{"to":"/story"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-film"}),_c('span',{staticClass:"menu-text"},[_vm._v("Story")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/report/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-bug"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('report')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/report/1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('reported_user')))])])])]}}])}),_c('router-link',{attrs:{"to":"/report/2"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('reported_post')))])])])]}}])}),_c('router-link',{attrs:{"to":"/report/3"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('reported_event')))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/system/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('system')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/system/change_password"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('change_password')))])])])]}}])}),_c('router-link',{attrs:{"to":"/system/send_message"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('send_message_to_users')))])])])]}}])}),_c('router-link',{attrs:{"to":"/system/send_email"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('send_email_to_users')))])])])]}}])})],1)])]),(_vm.hostname === 'localhost')?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/forMa/') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/forMa/paypalWebHook"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("PayPalWebHook")])])])]}}],null,false,100856155)}),_c('router-link',{attrs:{"to":"/forMa/temp"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("TransactionTemp")])])])]}}],null,false,1845832469)}),_c('router-link',{attrs:{"to":"/forMa/vip_ticket_link"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("VIPTicketLink")])])])]}}],null,false,3385114774)}),_c('router-link',{attrs:{"to":"/forMa/buyTicket"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("BuyTicket")])])])]}}],null,false,1088593431)}),_c('router-link',{attrs:{"to":"/forMa/editDocument"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("EditDocument")])])])]}}],null,false,2281120302)}),_c('router-link',{attrs:{"to":"/forMa/setHandlingFee"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Set Handling Fee")])])])]}}],null,false,1279530838)}),_c('router-link',{attrs:{"to":"/forMa/setTicketMinPrice"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Set TicketMinPrice")])])])]}}],null,false,1769217180)}),_c('router-link',{attrs:{"to":"/forMa/setKeywordList"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Set KeywordList")])])])]}}],null,false,3096885476)}),_c('router-link',{attrs:{"to":"/forMa/moveFollowers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Move Followers")])])])]}}],null,false,1634088537)})],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v("For MA")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }